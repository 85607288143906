<!--团队管理：人员管理-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
<!--        <h2 class="h2Style">-->
<!--          人员管理-->
<!--        </h2>-->
        <!--搜索栏-->
        <div class="control-box1 h2Style">
          <div class="cb-left">
            <el-input
                    v-model.trim="form.deviceName"
                    clearable
                    placeholder="制版机名称"
                    style="width: 200px;"
                    @clear="form.deviceName = undefined"
                    @keyup.enter.native="handleFilter"
            />
            <el-input
                v-model.trim="form.name"
                clearable
                placeholder="提报人"
                style="width: 200px;margin-left: 10px"
                @clear="form.name = undefined"
                @keyup.enter.native="handleFilter"
            />
            <el-button
                class="control-item"
                icon="el-icon-search"
                type="primary"
                @click="handleFilter"
            >
              搜索
            </el-button>
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addReportShow()"
            >
              添加
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="tableData"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column prop="date" label="提报时间" align="left" />
            <el-table-column prop="panelName" label="板材名称" align="left" />
            <el-table-column prop="name" label="提报人" align="left" />
            <el-table-column prop="deviceName" label="制版机名称" align="left" />
            <el-table-column prop="value1" label="1%/2%" align="left" />
            <el-table-column prop="value2" label="25%/30%" align="left" />
            <el-table-column prop="value3" label="50%" align="left" />
            <el-table-column prop="value4" label="70%/75%" align="left" />
            <el-table-column prop="value5" label="98%/99%" align="left" />

          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加账号-->
      <el-dialog
          v-if="reportShow"
          :visible.sync="reportShow"
          :title="dateAccount"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
          width="680px"
      >
        <el-form ref="inviteForm" label-width="100px" :model="report" :rules="rules.inviteRules">
          <el-form-item label="制版机:" prop="deviceId">
            <el-select v-model="report.deviceId" placeholder="请选择制版机" clearable>
              <el-option v-for="(item, index) in devices" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="版材:" prop="panelNme">
            <el-select v-model="report.panelNme" placeholder="请选择版材" clearable>
              <el-option v-for="(item, index) in panels" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="1%/2%:" prop="">
            <el-input-number v-model="report.value1" :precision="1" :step="0.1" :min="0.1" :max="100"></el-input-number>%
          </el-form-item>
          <el-form-item label="25%/30%:" prop="">
            <el-input-number v-model="report.value2" :precision="1" :step="0.1" :min="0.1" :max="100"></el-input-number>%
          </el-form-item>
          <el-form-item label="50%:" prop="">
            <el-input-number v-model="report.value3" :precision="1" :step="0.1" :min="0.1" :max="100"></el-input-number>%
          </el-form-item>
          <el-form-item label="70%/75%:" prop="">
            <el-input-number v-model="report.value4" :precision="1" :step="0.1" :min="0.1" :max="100"></el-input-number>%
          </el-form-item>
          <el-form-item label="98%/99%:" prop="">
            <el-input-number v-model="report.value5" :precision="1" :step="0.1" :min="0.1" :max="100"></el-input-number>%
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="cancel">取 消</el-button>
          <el-button type="primary" :loading="addReportBtnLoading" @click="addReport()">
            确定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api';
// import convert from "@/utils/convert";
export default {
  name: 'PersonManagement',
  data() {
    return {
      tableData: [],
      reportShow: false,
      addReportBtnLoading: false,
      listLoading: false,
      teamValues: {
        team: '',
      },
      devices: [],
      panels: [],
      // 弹窗标题
      dateAccount: '添加报告',
      // 添加账号
      report: {
        deviceId: undefined,
        panelNme: undefined,
        value1: undefined,
        value2: undefined,
        value3: undefined,
        value4: undefined,
        value5: undefined
      },
      leader: '',
      // 查询
      form: {
        deviceName: undefined,
        name: undefined,
        page: 1,
        count: 10,
      },
      rules: {
        inviteRules: {
          deviceId: [{ required: true, message: '请选择设备', trigger: ['blur', 'change']}],
          panelNme: [{ required: true, message: '请选择版材', trigger: ['blur', 'change'] }],
          value1: [{ required: true, message: '请填写2%', trigger: ['blur', 'change'] }],
          value2: [{ required: true, message: '请填写25%', trigger: ['blur', 'change'] }],
          value3: [{ required: true, message: '请填写50%', trigger: ['blur', 'change'] }],
          value4: [{ required: true, message: '请填写75%', trigger: ['blur', 'change'] }],
          value5: [{ required: true, message: '请填写98%', trigger: ['blur', 'change']}],
        },
      },
      totalCount: 0,
      buttons: '',
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.getReportList();
  },
  methods: {
    cancel(){
      this.reportShow = false
      this.emptyReport()
    },
    emptyReport(){
      this.report = {
        deviceId: undefined,
        panelNme: undefined,
        value1: undefined,
        value2: undefined,
        value3: undefined,
        value4: undefined,
        value5: undefined
      }
    },
    getReportList() {
      // console.log(this.form)
      API.QualityReportList(this.form).then((response) => {
        this.tableData = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    handleFilter() {
      this.form.count = 10;
      this.form.page = 1;
      this.getReportList();
    },
    addReportShow() {
      // console.log(this.roleIds)
      this.emptyReport()
      this.reportShow = true;
      this.devices = []
      this.panels = []
      // 设备
      API.QualityReportDeviceList({type : 1}).then((res) => {
        res.message.data.forEach((item) => {
          this.devices.push({label: item.name , value : item.id})
        })
      });
      // 版材
      API.PanelList({page : 1, count : 10000}).then((res) => {
        res.message.data.list.forEach((item) => {
          this.panels.push({label: item.name , value : item.name})
        })
      });
    },
    // 添加弹窗确定
    addReport() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          this.addReportBtnLoading = true;
          API.QualityReportAdd(this.report).then((res) => {
            if (res.message.success === true) {
              this.reportShow = false;
              this.addReportBtnLoading = false;
              this.$message.success('添加成功');
              this.getReportList();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.addReportBtnLoading = false;
          }).finally(() => {
            this.addReportBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getReportList();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getReportList();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
.input-width {
  width: 220px;
}
</style>
